class PreLoaderBarController implements angular.IComponentController {
    show: boolean;

    constructor(
        private $rootScope: any,
        private $scope: any
    ) {
        'ngInject';


        $rootScope.$on('$stateChangeStart', (event: any) => {
            this.show = true;
        });

        $rootScope.$on('$stateChangeSuccess', (event: any, toState: any, toParams: any, fromState: any) => {
            event.targetScope.$watch('$viewContentLoaded', () => {
                this.show = false;
            });
        });

        $rootScope.$on('preloader:active', (event: any) => {
            this.show = true;
        });
        $rootScope.$on('preloader:hide', (event: any) => {
            this.show = false;
        });
    }

}

export class PreLoaderBarComponent implements angular.IComponentOptions {
    static controller = PreLoaderBarController;
    static selector = 'preloaderbar';
    static template = `
    <div class="preloaderbar" ng-class="{ 'active': $ctrl.show }">
        <span class="bar"></span>
    </div>
    `;
}
