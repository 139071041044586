import * as angular from 'angular';
import {FormFieldComponent} from './form/form.component';
import {FormFieldService} from './formField.service';
import {FormPhoneFormComponent} from './phone-form/phone-form.component';
import {FormFieldViewComponent} from './field/view/view.component';
import {FormFieldListComponent} from './field/list/list.component';

// modules
export const moduleName = angular.module('application.form', [])
    .component(FormFieldComponent.selector, FormFieldComponent)
    .component(FormPhoneFormComponent.selector, FormPhoneFormComponent)
    .component(FormFieldListComponent.selector, FormFieldListComponent)
    .component(FormFieldViewComponent.selector, FormFieldViewComponent)
    .service(FormFieldService.selector, FormFieldService)
    .name;
