export class AlertService {
    static selector = 'AlertService';

    constructor(private $uibModal: any) {
        'ngInject';

        return this;
    }

    open = (config?: {
        cancelText: string;
        confirmText: string;
        title: string;
        type: string;
        backdrop: string;
        keyboard: any;
        content: string,
        onClose: Function,
        onConfirm: Function,
        onCancel: Function;
    }) => {
        return new Promise((resolve, reject) => {
            this.$uibModal.open({
                component: 'alertModal',
                animation: true,
                backdrop: config.backdrop || 'static',
                keyboard: config.keyboard,
                resolve: {config}
            })
                .result
                .then(resolve)
                .catch((result: any) => {
                    if (result && result.closed && config.onClose) {
                        config.onClose();
                    }

                    if (config.onCancel) {
                        config.onCancel();
                    }

                    if (result && result.error) {
                        this.error({
                            content: 'Não foi possível finalizar a operação. Tente novamente mais tarde.'
                        });
                    }

                    resolve();
                });
        });
    }

    error = (config = {}) => {
        // @ts-ignore
        return this.open({
            title: 'Ops!',
            content: 'Não foi possível concluir a operação. tente novamente mais tarde.',
            confirmText: 'Ok',
            cancelText: '',
            ...config,
            type: 'error'
        });
    }

    success = (config = {}) => {
        // @ts-ignore
        return this.open({
            title: 'Sucesso!',
            content: 'Operação realizada com sucesso!.',
            confirmText: 'Ok',
            ...config,
            type: 'success'
        });
    }

    warning = (config = {}) => {
        // @ts-ignore
        return this.open({
            title: 'Atenção!',
            content: '',
            confirmText: 'Ok',
            ...config,
            type: 'warning'
        });
    }

}
