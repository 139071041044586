import * as angular from 'angular';

class CustomerPhoneFormController implements angular.IComponentController {

    form: angular.IFormController;
    phone: any;
    remove?: () => void;
    name?: string;
    phonerequired?: boolean;
    ngDisabled?: boolean;

}

export class FormPhoneFormComponent implements angular.IComponentOptions {
    static selector = 'customerPhoneForm';
    static require = {
        form: '^form',
    };
    static bindings = {
        phone: '=',
        remove: '&?',
        name: '@?',
        phonerequired: '=',
        ngDisabled: '='
    };
    static controller = CustomerPhoneFormController;
    static template = require('./phone-form.html');
}
