export class Environment {
    static selector = 'environment';

    static get() {
        return {
            formsApiUrl: `https://api.mcforms.com`,
            // formsApiUrl: `http://localhost:5001/`,
        };
    }
}
