import * as angular from 'angular';
import * as _ from 'lodash-es';

export class FormFieldListController implements angular.IComponentController {
    modelForm: any;
    model: any;
    loading?: boolean;

    featureCustomFields: any[] = [];
    fields: any[] = [];
    subFields: any[] = [];
    customFieldBlocks: any[] = [];
    groupedFeatureCustomFields: any = [];
    private blockTabSelected: any;

    constructor(
        private $rootScope: any,
        private $scope: angular.IScope,
        private generalUtils: any,
        private StaticsService: any
    ) {
        'ngInject';
    }

    $onInit() {
        let self = this;
        this.loading = true;
        if (!this.model.customFields) {
            this.model.customFields = [];
        }

        let promises: Promise<any[]>[] = [
            this.StaticsService.getCustomFields({
                'subFieldSettings.active': true
            }),
            this.StaticsService.getCustomFields({
                'subFieldSettings.active': false
            }),
            this.StaticsService.getCustomFieldBlocks()
        ];

        Promise.all(promises)
            .then(([subFields, fields, customFieldBlocks]) => {
                delete this.loading;
                this.customFieldBlocks = customFieldBlocks;

                fields = _.filter(fields, (item) => {
                    return _.find(self.$rootScope.formSettings.components, (f) => f.customField._id === item._id);
                });

                this.updateIndex(fields);

                this.fields = fields;
                this.subFields = subFields;

                for (let sfield of subFields) {
                    this.$rootScope.$on(`${sfield.feature}_${sfield._id}`, (observer: any, data: any) => {
                        let field = _.find(this.subFields, (sf) => String(sf._id) === String(data.subField));
                        let fieldAlreadyExists = _.find(this.featureCustomFields, (fcf) => String(fcf._id) === String(field._id));

                        if (this.isObject(data.v)) {
                            let valueMatch = _.find(field.subFieldSettings.values, (o) => String(o._id) === String(data.v._id));
                            if (valueMatch) {
                                if (!fieldAlreadyExists) {
                                    this.featureCustomFields.push(field);
                                    this.updateIndex(this.featureCustomFields);

                                    let fieldInModel = _.find(this.model.customFields, item => (item || {}).customField === field._id);
                                    if (!_.isEmpty(field.dependentFields)) {
                                        for (let dfield of field.dependentFields) {
                                            this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                                                v: (fieldInModel || {}).v,
                                                subField: dfield
                                            });
                                        }
                                    }
                                    this.groupAndOrderCustomFields();
                                }
                            } else {
                                if (fieldAlreadyExists) {
                                    this.model.customFields.splice(fieldAlreadyExists.$modelIndex, 1);

                                    if (!_.isEmpty(fieldAlreadyExists.dependentFields)) {
                                        for (let dfield of fieldAlreadyExists.dependentFields) {
                                            this.$rootScope.$broadcast(`${fieldAlreadyExists.feature}_${dfield}`, {
                                                v: null,
                                                subField: dfield
                                            });
                                        }
                                    }
                                    _.remove(this.featureCustomFields, (o) => String(o._id) === String(fieldAlreadyExists._id));
                                    this.updateIndex(this.featureCustomFields);
                                    this.groupAndOrderCustomFields();
                                }
                            }
                        } else if (this.isArray(data.v)) {
                            let valueMatch;

                            for (let val of data.v) {
                                valueMatch = _.find(field.subFieldSettings.values, (o) => String(o._id) === String(val._id));
                                if (valueMatch) {
                                    break;
                                }
                            }

                            if (valueMatch) {
                                if (!fieldAlreadyExists) {
                                    this.featureCustomFields.push(field);
                                    this.updateIndex(this.featureCustomFields);

                                    let fieldInModel = _.find(this.model.customFields, item => (item || {}).customField === field._id);
                                    if (!_.isEmpty(field.dependentFields)) {
                                        for (let dfield of field.dependentFields) {
                                            this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                                                v: (fieldInModel || {}).v,
                                                subField: dfield
                                            });
                                        }
                                    }
                                    this.groupAndOrderCustomFields();
                                }
                            } else {
                                if (fieldAlreadyExists) {
                                    this.model.customFields.splice(fieldAlreadyExists.$modelIndex, 1);
                                    if (!_.isEmpty(fieldAlreadyExists.dependentFields)) {
                                        for (let dfield of fieldAlreadyExists.dependentFields) {
                                            this.$rootScope.$broadcast(`${fieldAlreadyExists.feature}_${dfield}`, {
                                                v: null,
                                                subField: dfield
                                            });
                                        }
                                    }

                                    _.remove(this.featureCustomFields, (o) => String(o._id) === String(fieldAlreadyExists._id));
                                    this.updateIndex(this.featureCustomFields);
                                    this.groupAndOrderCustomFields();
                                }
                            }

                        } else {
                            if (data.v) {
                                if (!fieldAlreadyExists) {
                                    this.featureCustomFields.push(field);
                                    this.updateIndex(this.featureCustomFields);

                                    let fieldInModel = _.find(this.model.customFields, item => (item || {}).customField === field._id);

                                    if (!_.isEmpty(field.dependentFields)) {
                                        for (let dfield of field.dependentFields) {
                                            this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                                                v: (fieldInModel || {}).v,
                                                subField: dfield
                                            });
                                        }
                                    }
                                    this.groupAndOrderCustomFields();
                                }
                            } else {
                                if (fieldAlreadyExists) {
                                    this.model.customFields.splice(fieldAlreadyExists.$modelIndex, 1);

                                    if (!_.isEmpty(fieldAlreadyExists.dependentFields)) {
                                        for (let dfield of fieldAlreadyExists.dependentFields) {
                                            this.$rootScope.$broadcast(`${fieldAlreadyExists.feature}_${dfield}`, {
                                                v: null,
                                                subField: dfield
                                            });
                                        }
                                    }

                                    _.remove(this.featureCustomFields, (o) => String(o._id) === String(fieldAlreadyExists._id));
                                    this.updateIndex(this.featureCustomFields);
                                    this.groupAndOrderCustomFields();
                                }
                            }
                        }
                    });
                }

                fields.map((field: any) => {
                    let fieldInModel = _.find(this.model.customFields, item => (item || {}).customField === field._id);
                    if (!_.isEmpty(field.dependentFields)) {
                        for (let dfield of field.dependentFields) {
                            this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                                v: (fieldInModel || {}).v,
                                subField: dfield
                            });
                        }
                    }
                });

                this.groupAndOrderCustomFields();
                setTimeout(() => {
                    this.$scope.$apply();
                }, 100);

            })
            .catch(() => {
                delete this.loading;
                setTimeout(() => {
                    this.$scope.$apply();
                }, 100);
            });
    }

    private updateIndex(fields: any[]) {
        let lastIndex: number = null;

        this.featureCustomFields = fields.map((field) => {
            if (!field.$modelIndex) {
                let i = this.model.customFields.findIndex((item: any) => (item || {}).customField === field._id);

                if (i === -1) {
                    i = null;
                    if (lastIndex == null) {
                        lastIndex = this.model.customFields.length + 1;
                    }
                } else {
                    if (lastIndex < i) {
                        lastIndex = i + 1;
                    }
                }

                return {
                    ...field,
                    $modelIndex: i
                };
            } else {
                return field;
            }
        });

        this.featureCustomFields = _.sortBy(this.featureCustomFields, (fcf) => {
            return fcf.$modelIndex;
        });

        this.featureCustomFields = this.featureCustomFields.map((field) => {
            let i = field.$modelIndex;
            if (i == null) {
                i = lastIndex;
            }

            lastIndex++;
            return {
                ...field,
                $modelIndex: i
            };
        });
    }

    private groupAndOrderCustomFields() {
        this.groupedFeatureCustomFields = _.groupBy(this.featureCustomFields, (item) => {
            return item.customFieldBlockAttach && item.customFieldBlockAttach._id;
        });
        this.groupedFeatureCustomFields = _.map(_.keys(this.groupedFeatureCustomFields), (item) => {
            let block = _.find(this.customFieldBlocks, blk => blk._id === item);

            let orderedList = _.orderBy(this.groupedFeatureCustomFields[item], cf => cf.position || 999999);
            let blockRequired = _.find(orderedList, (item) => (item.settings || {}).required);
            return {
                _id: (block || {})._id,
                name: (block || {}).name || '',
                position: (block || {}).position || 999999,
                val: orderedList,
                isExpanded: (block || {}).isExpanded,
                required: !!blockRequired
            };
        });
        this.groupedFeatureCustomFields = _.orderBy(this.groupedFeatureCustomFields, (item) => item.position);

        if (!this.blockTabSelected) {
            this.blockTabSelected = this.groupedFeatureCustomFields[0]._id;
        }
    }

    private isObject(value: any): boolean {
        return value && typeof value === 'object' && value.constructor === Object;
    }

    private isArray(value: any): boolean {
        return value && typeof value === 'object' && value.constructor === Array;
    }

}

export class FormFieldListComponent implements angular.IComponentOptions {
    static selector = 'formFieldList';
    static bindings = {
        modelForm: '=',
        model: '=',
        loading: '=?'
    };
    static controller = FormFieldListController;
    static template = require('./list.html');
}
