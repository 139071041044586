import {AppComponent} from './components/app/app.component';
import * as _ from 'lodash';

export const routing = (
    $stateProvider: angular.ui.IStateProvider,
    $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
    'ngInject';

    $stateProvider.state('app', {
        url: '/:formCode',
        params: {
            formCode: null
        },
        component: AppComponent.selector,
        resolve: {
            form: ['$stateParams', 'FormsService', 'Restangular', async ($stateParams: any, FormsService: any, Restangular: any) => {
                let formInfo = await FormsService.getForm($stateParams.formCode);
                if (!_.isEmpty(formInfo)) {
                    Restangular
                        .setBaseUrl(`${formInfo.apiDomain}`)
                        .setDefaultHeaders({
                            'Authorization': `Bearer ${formInfo.accessToken}`,
                            'domain': formInfo.domain,
                            'api': 'forms'
                        })
                        .setDefaultHttpFields({'timeout': 180000});
                }
                return formInfo;
            }]
        }
    });

    $urlRouterProvider.otherwise('/');
};
