export class FormsRestangularFactory {
    static selector = 'FormsRestangular';

    constructor(private Restangular: any, private environment: any) {
        'ngInject';

        return this.Restangular.withConfig((RestangularConfigurer: any) => {
            RestangularConfigurer
                .setBaseUrl(this.environment.get().formsApiUrl)
                .setRestangularFields({id: '_id'})
                .setDefaultHttpFields({'timeout': 600000});
        });
    }
}
