import * as angular from 'angular';
import {PreLoaderBarComponent} from './preLoaderBar/preLoaderBar.component';
import {AlertModalComponent} from './alertModal/alertModal.component';
import {ContentComponent} from './content/content.component';
import {ContentBodyComponent} from './content/contentBody.component';
import {PreLoaderComponent} from './preLoader/preLoader.component';

export const moduleName =
    angular.module('application.layout', [])
        .component(PreLoaderBarComponent.selector, PreLoaderBarComponent)
        .component(PreLoaderComponent.selector, PreLoaderComponent)
        .component(AlertModalComponent.selector, AlertModalComponent)
        .component(ContentComponent.selector, ContentComponent)
        .component(ContentBodyComponent.selector, ContentBodyComponent)
        .name;
