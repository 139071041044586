import * as angular from 'angular';
import {config} from './app.config';
import {Environment} from './environment';
import {FormsRestangularFactory} from './factories/forms-restangular.factory';
import {StaticsService} from './services/statics.services';
import {FormsService} from './services/forms.services';
import {StaffsService} from './services/staffs.services';

export const moduleName =
    angular.module('application.networking', [])
        .factory(FormsRestangularFactory.selector, FormsRestangularFactory)
        .constant(Environment.selector, Environment)
        .service(StaticsService.selector, StaticsService)
        .service(StaffsService.selector, StaffsService)
        .service(FormsService.selector, FormsService)
        .config(config)
        .name;
