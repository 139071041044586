export class FormsService {

    static selector = 'FormsService';

    constructor(
        private FormsRestangular: any,
        private Restangular: any,
        private $rootScope: any
    ) {
        'ngInject';

        return this;
    }

    getForm = (formCode: any) => {
        return new Promise((resolve, reject) => {
            this.FormsRestangular.all('').customGET(formCode)
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }

    saveForm = (formData: any, settings: {contactSource: String}) => {
        let url = 'import/contacts';
        switch (settings.contactSource) {
            case 'lead':
                url = 'import/leads';
                break;
            case 'prospect':
                url = 'import/prospects';
                break;
            case 'customer':
                url = 'import/contacts';
                break;
        }
        return new Promise(((resolve, reject) => {
            this.Restangular.all(url).post(formData)
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        }));
    }
}
