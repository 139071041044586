import _ = require('lodash');
import moment = require('moment');

export class GeneralUtilFactory {
    static selector = 'generalUtils';

    constructor(
        $rootScope: any,
        localStorageService: any,
        $state: any,
        environment: any,
        AlertService: any
    ) {
        'ngInject';


        function generateYearsStringByMonth(months: any) {
            if (!months) {
                return;
            }
            if (months < 12) {
                return months + ' meses';
            }
            if (months === 12) {
                return '1 ano';
            }
            months = (months / 12).toString().split('.');
            if (months[1]) {
                return months[0] + ' anos e ' + increaseFloatToNextNumber(months[1]) + ' meses';
            } else {
                return months + ' anos';
            }
        }


        function getVehicleImage(environment: any, vehicle: any) {
            if (!vehicle) {
                return;
            }
            var modelId = vehicle.idName, specificationId = vehicle.idSpecification;

            if (specificationId) {
                return `${environment.get().apiMobile}public/models/${modelId}/specifications/${specificationId}/image`;
            }

            return `${environment.get().apiMobile}public/models/${modelId}/image`;
        }


        function increaseFloatToNextNumber(number: any) {
            number.split('.');
            if (number[2] >= 6) {
                // tslint:disable-next-line:radix
                return parseInt(number[0]) + 1;
            }
            return number[0];
        }


        function formatPhone(phone: any) {
            if (!phone || phone === '' || !(/^\d+$/.test(phone))) {
                return phone;
            }

            if (phone.substring(0, 1) === '(') {
                return phone;
            }


            var aux1 = phone.substring(0, 2);
            var aux2 = phone.substring(2, 7);
            var aux3 = phone.substring(7, 12);

            return '(' + aux1 + ')' + ' ' + aux2 + ' ' + aux3;
        }


        function formatCPF(cpf: any) {
            if (!cpf) {
                return '';
            }

            if (cpf.substring(3, 4) === '.') {
                return cpf;
            }

            var aux1 = cpf.substring(0, 3);
            var aux2 = cpf.substring(3, 6);
            var aux3 = cpf.substring(6, 9);
            var aux4 = cpf.substring(9, 11);
            return aux1 + '.' + aux2 + '.' + aux3 + '-' + aux4;
        }


        function formatCNPJ(cnpj: any) {
            if (!cnpj) {
                return '';
            }

            if (cnpj.substring(2, 3) === '.') {
                return cnpj;
            }

            var aux1 = cnpj.substring(0, 2);
            var aux2 = cnpj.substring(2, 5);
            var aux3 = cnpj.substring(5, 8);
            var aux4 = cnpj.substring(8, 12);
            var aux5 = cnpj.substring(12, 14);

            return aux1 + '.' + aux2 + '.' + aux3 + '/' + aux4 + '-' + aux5;
        }


        function formatCPForCNPJ(doc: any) {
            if (!doc) {
                return '';
            }

            let text = doc.replace(/[\.|-|/| ]+/, '');

            if ((!/^[0-9]{11,16}$/.test(text))) {
                return doc;
            }

            if (doc.substring(3, 4) === '.') {
                return doc;
            }

            if (doc.substring(2, 3) === '.') {
                return doc;
            }

            if (doc.length === 11) {
                return formatCPF(doc);
            }

            return formatCNPJ(doc);
        }


        function checkIfIsCpfOrCnpj(value: any) {
            if (!value) {
                return;
            }

            value = value.toString();
            value = value.replace(/[^0-9]/g, '');

            if (value.length === 11) {
                return 'cpf';
            }
            if (value.length === 14) {
                return 'cnpj';
            }
        }


        function moveArrayElementFromTo(array: any, from: any, to: any) {
            if (!array || from === undefined || to === undefined) {
                console.error('required parameters not informed');
                return [];
            }
            // @ts-expect-error TS(2304): Cannot find name 'angular'.
            var copyArray = angular.copy(array);
            copyArray.splice(to, 0, copyArray.splice(from, 1)[0]);

            return copyArray;
        }


        function alert(title: any, text: any, confirmTextBtn: any, cancelTextBtn: any, callback: any) {
            AlertService.warning({
                title,
                content: text,
                confirmText: confirmTextBtn,
                cancelText: cancelTextBtn,
                onConfirm: () => callback(true),
                onCancel: () => callback()
            });
        }


        function onError(title: any, text: any, confirmTextBtn: any, callback: any) {
            AlertService.error({
                title,
                content: text,
                confirmText: confirmTextBtn,
                onConfirm: () => callback(true),
                onCancel: () => callback()
            });
        }


        function onSuccess(title: any, text: any, confirmTextBtn: any, cancelTextBtn: any, callback: any) {

            AlertService.success({
                title,
                content: text,
                confirmText: confirmTextBtn,
                cancelText: cancelTextBtn,
                onClose: () => callback(true),
                onConfirm: () => callback(true),
                onCancel: () => callback()
            });
        }


        function onWarning(title: any, text: any, confirmTextBtn: any, cancelTextBtn: any, callback: any) {
            AlertService.warning({
                title,
                content: text,
                confirmText: confirmTextBtn,
                cancelText: cancelTextBtn,
                onConfirm: () => callback(true),
                onCancel: () => callback()
            });
        }


        function splitFullName(fullName: any, callback: any) {
            if (!fullName) {
                callback(['', '']);
            }
            var splitName = fullName.split(' ');
            var firstName = splitName[0];
            var lastName = splitName.length > 0 ? splitName.slice(1, splitName.length).join(' ') : '';
            callback([firstName, lastName]);
        }


        function concatFullName(splitName: any) {
            return splitName[0] + ' ' + splitName[1];
        }


        function validatePlate(plate: any) {
            if (!plate || plate.substring(3, 4) === '-') {
                return;
            }

            var firstPlate = plate.substring(0, 3);
            var secondPlate = plate.substring(3);
            return firstPlate + '-' + secondPlate;
        }


        function validateKilometer(km: any) {
            if (!km) {
                return 0;
            }

            // tslint:disable-next-line:radix
            return parseInt(km);

        }


        function formatInspections(time: any) {
            return 'Revisão de ' + time + ' quilometros';
        }


        function validateYear(year: any) {
            // tslint:disable-next-line:radix
            return parseInt(year);

        }


        function startLoader() {
            $rootScope.$broadcast('preloader:active');
        }


        function hideLoader() {
            $rootScope.$broadcast('preloader:hide');
        }


        function typePerson(doc: any) {
            if (!doc || doc === '') {
                return '';
            }

            if (doc.length === 14) {
                return 'Física';
            }


            return 'Jurídica';

        }


        function typeDoc(doc: any) {
            if (!doc || doc === '') {
                return '';
            }

            if (doc.length === 14) {
                return 'CPF';
            }

            return 'CNPJ';
        }


        function typeDate(doc: any) {
            if (!doc || doc === '') {
                return '';
            }

            if (doc.length === 14) {
                return 'Data de aniversário';
            }

            return 'Data de criação';
        }


        function isNumber(str: any) {
            return /^\d+$/.test(str);
        }

        function dateIsValid(dateString: any, dateType: any, hour: any) {


            if (_.isEmpty(dateString)) {
                return false;
            }

            var date;
            if (_.isEmpty(hour)) {

                date = moment(dateString, 'DD/MM/YYYY');
            } else {

                date = moment(dateString + hour, 'DD/MM/YYYYHH:mm');
            }


            if (!date.isValid()) {
                return false;
            }


            var currentDate = moment();


            // @ts-ignore
            var preciseDiff = moment.preciseDiff(date, currentDate, true);

            switch (dateType) {
                case 'scheduling':
                    if (preciseDiff.firstDateWasLater && preciseDiff.years === 0 && preciseDiff.months < 6) {
                        return true;
                    } else {
                        return false;
                    }
                    break;
                case 'foundation':
                    if (!preciseDiff.firstDateWasLater) {
                        return true;
                    } else {
                        return false;
                    }
                    break;
                case 'birthdate':
                    if (!preciseDiff.firstDateWasLater && preciseDiff.years >= 18 && preciseDiff.years <= 150) {
                        return true;
                    } else {
                        return false;
                    }
                default:
                    return false;
            }

        }


        function breakIntervalInMinutes(startHour: any, endHour: any, interval: any) {
            if (!startHour || !endHour || !interval) {
                return [];
            }

            var dayString = '28-03-1990 ';

            var start = moment(dayString + startHour, 'DD-MM-YYYY HH:mm');

            var end = moment(dayString + endHour, 'DD-MM-YYYY HH:mm');
            var result = [];

            while (start <= end) {
                result.push(start.format('HH:mm'));
                start.add(interval, 'minutes');
            }

            return result;
        }


        function formatDateSchedule(d: any) {
            if (d.substring(4, 5) === '-') {
                return;
            }

            var date = d.split('/');
            return date[2] + '-' + date[1] + '-' + date[0];
        }


        function convertFileToBinaryData(files: any, callback: any) {
            for (var i = 0, f = files[i]; i !== files.length; ++i) {
                var reader = new FileReader();
                var data;
                // tslint:disable-next-line:typedef
                reader.onload = function (e) {
                    if (!e) {
                        // @ts-ignore
                        data = reader.content;
                    } else {
                        data = e.target.result;
                    }

                    callback(null, data);
                };

                if (!FileReader.prototype.readAsBinaryString) {
                    // tslint:disable-next-line:typedef
                    FileReader.prototype.readAsBinaryString = function (fileData) {
                        var binary = '';
                        var pt = this;
                        var reader = new FileReader();
                        reader.onload = function () {
                            // @ts-ignore
                            var bytes = new Uint8Array(reader.result);
                            var length = bytes.byteLength;
                            for (var i = 0; i < length; i++) {
                                binary += String.fromCharCode(bytes[i]);
                            }

                            pt.content = binary;
                            $(pt).trigger('onload');
                        };
                        reader.readAsArrayBuffer(fileData);
                    };
                }

                reader.readAsBinaryString(f);

            }
        }


        function getExtFile(file: any) {
            var regexExt = /(?:\.([^.]+))?$/;
            return regexExt.exec(file.name)[1];
        }


        function generateTimeOptions(BranchSettings: any) {
            if (!BranchSettings.startTime && !BranchSettings.endTime && !BranchSettings.interval) {
                return [];
            }

            return breakIntervalInMinutes(BranchSettings.startTime, BranchSettings.endTime, BranchSettings.interval);
        }


        function capitalizeString(string: any) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }


        function getActiveModule(modules: any) {
            if (!modules) {
                return '';
            }

            if (modules.vehicleProduct) {
                return 'vehicleProduct';
            }

            if (modules.financialProduct) {
                return 'financialProduct';
            }

            if (modules.product) {
                return 'product';
            }

            return '';
        }


        function getActiveModuleLabel(modules: any) {
            if (!modules) {
                return '';
            }

            if (modules.vehicleProduct) {
                return 'Veículo';
            }

            if (modules.financialProduct) {
                return 'Produto Financeiro';
            }

            if (modules.product) {
                return 'Produto';
            }

            return '';
        }

        // @ts-ignore
        return {
            formatPhone: formatPhone,
            formatCPF: formatCPF,
            formatCNPJ: formatCNPJ,
            checkIfIsCpfOrCnpj: checkIfIsCpfOrCnpj,
            alert: alert,
            generateYearsStringByMonth: generateYearsStringByMonth,
            getVehicleImage: getVehicleImage,
            onSuccess: onSuccess,
            onError: onError,
            onWarning: onWarning,
            splitFullName: splitFullName,
            startLoader: startLoader,
            hideLoader: hideLoader,
            validatePlate: validatePlate,
            validateKilometer: validateKilometer,
            validateYear: validateYear,
            concatFullName: concatFullName,
            typePerson: typePerson,
            formatCPForCNPJ: formatCPForCNPJ,
            isNumber: isNumber,
            moveArrayElementFromTo: moveArrayElementFromTo,
            formatInspections: formatInspections,
            dateIsValid: dateIsValid,
            breakIntervalInMinutes: breakIntervalInMinutes,
            formatDateSchedule: formatDateSchedule,
            typeDoc: typeDoc,
            typeDate: typeDate,
            convertFileToBinaryData: convertFileToBinaryData,
            getExtFile: getExtFile,
            generateTimeOptions: generateTimeOptions,
            capitalizeString: capitalizeString,
            getActiveModule: getActiveModule,
            getActiveModuleLabel: getActiveModuleLabel,
            // @ts-ignore
            defaultModelRefItems: ['contact', 'treatment', 'task', 'communicationCustomer', 'organization', 'schedule', 'offeropportunitieshistory', 'partner']
        };
    }


}
