import * as angular from 'angular';
import _ = require('lodash');

class CustomFieldCreateController implements angular.IComponentController {
    fieldType: any;
    fieldValues: any;
    fieldModel: { k: any, v: any, customField: any };
    val: any;
    fieldId: any;
    fieldLabel: any;
    fieldChanged: any;

    constructor(
        private $scope: any
    ) {
        'ngInject';
    }

    $onInit() {
        switch (this.fieldType) {
            case 'enum':
                let result = _.find(this.fieldValues, (o) => o._id === ((this.fieldModel || {}).v ? this.fieldModel.v._id : null));
                this.val = result;

                break;
            case 'set':
                this.val = _.map((this.fieldModel || {}).v, (field) => {
                    let fieldFound = _.find(this.fieldValues, (o) => o._id === (field ? field._id : null));
                    return fieldFound ? fieldFound : field;
                });
                break;
            default:
                this.val = (this.fieldModel || {}).v;
                break;
        }
    }

    valChange = (data: any) => {
        this.fieldModel = {
            customField: this.fieldId,
            k: this.fieldLabel,
            v: data
        };

        this.fieldChanged({data: this.fieldModel});
    }

}

export class CustomFieldCreateComponent implements angular.IComponentOptions {
    static selector = 'customFieldCreate';
    static bindings = {
        fieldChanged: '&?',
        fieldId: '<?',
        fieldModel: '=?',
        fieldType: '<?',
        fieldLabel: '<?',
        fieldDisabled: '=?',
        fieldValues: '<?',
        fieldRequired: '<?'
    };
    static controller = CustomFieldCreateController;
    static template = require('./create.html');
    static options = {
        scope: true
    };
}
