import * as angular from 'angular';
import * as moment from 'moment';

export class FormFieldService {
    static selector = 'CustomerService';

    constructor(
        private localStorageService: any,
        private $rootScope: any
    ) {
        'ngInject';

        return this;
    }

}
