import * as $ from 'jquery';

export class ChosenDirective implements angular.IDirective {
    static selector = 'chosen';
    priority = 0;

    static factory(): ng.IDirectiveFactory {
        const directive = () => new ChosenDirective();
        // @ts-ignore
        directive.$inject = [];
        return directive;

    }

    constructor() {
        'ngInject';
    }

    link: angular.IDirectiveLinkFn = ($scope: any, $el: any, $atts: any) => {
        if ($atts.multiple || $atts.deselect === 'false') {
            return;
        }

        let opt = $('<option value="undefined">Selecione</option>');

        $atts.$observe('required', (isRequired: any) => {
            if (isRequired) {
                opt.remove();
            } else {
                $el.prepend(opt);
            }
        });
    }
}
