import * as $ from 'jquery';

export class PhoneValidateDirective implements angular.IDirective {
    static selector = 'phoneValidate';
    priority = 0;
    require = 'ngModel';

    static factory(): ng.IDirectiveFactory {
        const directive = () => new PhoneValidateDirective();
        // @ts-ignore
        directive.$inject = [];
        return directive;
    }

    constructor() {
        'ngInject';
    }

    link: angular.IDirectiveLinkFn = (scope: any, element: any, attr: any, mCtrl: any) => {
        function setCaretPosition(elemArray: any, caretPos: any) {
            let el = elemArray[0];
            // ^ this is used to not only get "focus", but
            // to make sure we don't have it everything -selected-
            // (it causes an issue in chrome, and having it doesn't hurt any other browser)

            if (el !== null) {

                if (el.createTextRange) {
                    var range = el.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                    return true;
                } else {
                    // (el.selectionStart === 0 added for Firefox bug)
                    if (el.selectionStart || el.selectionStart === 0) {
                        el.focus();
                        el.setSelectionRange(caretPos, caretPos);
                        return true;
                    } else { // fail city, fortunately this never happens (as far as I've tested) :)
                        el.focus();
                        return false;
                    }
                }
            }
        }

        $(element).on('keyup', function (event: any) {
            let el = $(this);

            // @ts-ignore
            if (el.val().length > 15) {
                // @ts-ignore
                el.mask('(000) 000 000 009');
                setCaretPosition(el, 9999);
                // @ts-ignore
            } else if (el.val().length === 15) {
                // @ts-ignore
                el.mask('(00) 00000-00009');
                setCaretPosition(el, 9999);
            } else {
                // @ts-ignore
                el.mask('(00) 0000-000009');
                setCaretPosition(el, 9999);
            }
        });
        mCtrl.$validators.phoneValidate = function (value: any) {
            if (!value || value.length === 0) {
                return true;
            }
            var cleanValue = value.replace(/(\(|\)|\ |\D|\-)/ig, '');
            return /^[0-9]{10,12}$/.test(cleanValue);
        };
        mCtrl.$parsers.push((value: any) => value.replace(/(\(|\)|\ |\D|\-)/ig, ''));
    }
}
