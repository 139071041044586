export const config = ($translateProvider: any) => {
    'ngInject';

    $translateProvider.useStaticFilesLoader({
        prefix: './assets/i18n/',
        suffix: '.json'
    });
    $translateProvider.preferredLanguage('pt-br');
    $translateProvider.useSanitizeValueStrategy(null);
};
