import * as angular from 'angular';
import {GeneralUtilFactory} from './factories/generalUtils.factory';
import {AlertService} from './services/alert.service';
import {AppConfigFactory} from './factories/appConfig.factory';
import {NumberMaskService} from './services/numberMask.service';
import {ChosenDirective} from './directives/chosen.directive';
import {PhoneValidateDirective} from './directives/phoneValidate.directive';

export const moduleName =
    angular.module('application.utils', [])
        .factory(GeneralUtilFactory.selector, GeneralUtilFactory)
        .factory(AppConfigFactory.selector, AppConfigFactory)
        .service(AlertService.selector, AlertService)
        .service(NumberMaskService.selector, NumberMaskService)
        .directive(ChosenDirective.selector, ChosenDirective.factory())
        .directive(PhoneValidateDirective.selector, PhoneValidateDirective.factory())
        .name;
