class PreLoaderController implements angular.IComponentController {

    constructor(
        private $rootScope: any,
        private $scope: any
    ) {
        'ngInject';
    }
}

export class PreLoaderComponent implements angular.IComponentOptions {
    static controller = PreLoaderController;
    static selector = 'preLoader';
    static transclude = true;
    static bindings = {
        mode: '@?mode',
        size: '@?'
    };
    static template = `
    <div class="preloader-wrapper">
                    <md-progress-linear
                            ng-if="mode == 'linear'"
                            md-mode="indeterminate"></md-progress-linear>

                    <div class="content lds-css ng-scope">
                        <div ng-if="!mode && !size" class="ellipsis-large">
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                        <div ng-if="!mode && size === 'md'" class="ellipsis-md">
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                        <div ng-if="!mode && size === 'small'" class="ellipsis-small">
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                        <ng-transclude></ng-transclude>
                    </div>
                </div>
    `;
}
