export const run = (uibPaginationConfig: any, $rootScope: any) => {
    'ngInject';

    uibPaginationConfig.previousText = '‹';
    uibPaginationConfig.nextText = '›';
    uibPaginationConfig.firstText = '«';
    uibPaginationConfig.lastText = '»';

    $rootScope.$on('$stateChangeStart', function (evt: any, toState: any, toParams: any, fromState: any, fromParams: any) {
        $rootScope.previousState = fromState;
        $rootScope.previousParams = fromParams;
        $rootScope.$state = toState;
    });
};
