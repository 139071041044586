import * as angular from 'angular';
import 'angular-ui-bootstrap';

/**
 * Import Module Components
 */
import {AppComponent} from './components/app/app.component';
import {RootComponent} from './components/root/root.component';

/**
 * Import Module Configuration
 */
import '@uirouter/angularjs/lib/legacy/stateEvents.js';
import {configuration} from './core.configuration';
import {routing} from './core.routes';
import {run} from './core.run';

export const moduleName =
    angular.module('application.core', [
        'ui.router',
        'ui.bootstrap',
        'ui.router.state.events'
    ])
        .run(run)
        /**
         * Register Module Components
         */

        .component(AppComponent.selector, AppComponent)
        .component(RootComponent.selector, RootComponent)

        /**
         * Register Module Configuration
         */
        .config(configuration)
        .config(routing)
        .name;
