class I18nController implements angular.IComponentController {
    constructor(private $scope: any, private $translate: any) {
        'ngInject';
    }

    $onInit() {
        this.$scope.lang = 'English';
        this.$scope.setLang = setLang;
        this.$scope.getFlag = getFlag;


        function setLang(lang: any) {
            switch (lang) {
                case 'English':
                    this.$translate.use('en');
                    break;
                case 'Español':
                    this.$translate.use('es');
                    break;
                case '中文':
                    this.$translate.use('zh');
                    break;
                case '日本語':
                    this.$translate.use('ja');
                    break;
                case 'Portugal':
                    this.$translate.use('pt');
                    break;
                case 'Русский язык':
                    this.$translate.use('ru');
                    break;
            }
            return this.$scope.lang = lang;
        }

        function getFlag() {
            var lang;
            lang = this.$scope.lang;
            switch (lang) {
                case 'English':
                    return 'flags-american';
                    break;
                case 'Español':
                    return 'flags-spain';
                    break;
                case '中文':
                    return 'flags-china';
                    break;
                case 'Portugal':
                    return 'flags-portugal';
                    break;
                case '日本語':
                    return 'flags-japan';
                    break;
                case 'Русский язык':
                    return 'flags-russia';
                    break;
            }
        }
    }
}

export class I18nComponent implements angular.IComponentOptions {
    static selector = 'langCtrl';
    static controller = I18nController;
}
