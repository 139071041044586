export class AppConfigFactory {
    static selector = 'appConfig';

    constructor() {
        'ngInject';

        const pageTransitionOpts = [{
            name: 'Fade up',
            'class': 'animate-fade-up'
        }, {
            name: 'Scale up',
            'class': 'ainmate-scale-up'
        }, {
            name: 'Slide in from right',
            'class': 'ainmate-slide-in-right'
        }, {
            name: 'Flip Y',
            'class': 'animate-flip-y'
        }];
        const date = new Date();
        const year = date.getFullYear();
        const main = {
            brand: 'Magnet Customer',
            year: year,
            layout: 'wide',
            menu: 'vertical',
            isMenuCollapsed: false,
            fixedHeader: true,
            fixedSidebar: true,
            pageTransition: pageTransitionOpts[0],
            skin: '11'
        };
        const color = {
            primary: '#008AC7',
            success: '#8BC34A',
            info: '#00BCD4',
            infoAlt: '#7E57C2',
            warning: '#FFCA28',
            danger: '#DF1619',
            text: '#3D4051',
            gray: '#EDF0F1'
        };

        return {
            pageTransitionOpts: pageTransitionOpts,
            main: main,
            color: color
        };
    }
}
