export class NumberMaskService {
    static selector = 'NumberMasks';
    masks = [
        {label: 'Valor', value: 'raw', group: 'Numérico', apply: (v: any) => this.$filter('number')(v, 0)},
        {label: 'Duas casas decimais', value: '.2', group: 'Numérico', apply: (v: any) => this.$filter('number')(v, 2)},
        {label: 'Reais', value: 'BRL', group: 'Monetário', apply: (v: any) => this.$filter('currency')(v, 'R$')},
        {label: 'Dolar', value: 'USD', group: 'Monetário', apply: (v: any) => this.$filter('currency')(v, 'USD')},
        {label: 'Euro', value: 'EUR', group: 'Monetário', apply: (v: any) => this.$filter('currency')(v, 'EUR')},
        {
            label: 'Peso Argentino',
            value: 'ARS',
            group: 'Monetário',
            apply: (v: any) => this.$filter('currency')(v, 'ARS')
        },
        {label: 'Porcentagem', value: 'percent', group: 'Numérico', apply: (v: any) => this.$filter('number')(v, 0)},
    ];

    constructor(private $filter: any) {
        'ngInject';
        return this;
    }

    apply(mask: string = 'raw', value: any = 0) {
        let m = this.masks.find(f => f.value === mask);
        if (!m) {
            return value;
        }
        return m.apply(value);
    }
}
