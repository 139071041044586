export class StaticsService {

    static selector = 'StaticsService';

    constructor(private Restangular: any, private $rootScope: any) {
        'ngInject';

        return this;
    }

    getCustomFields = (opts = {}) => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('customfields').customGET('', opts)
                .then((data: any) => {
                    data.forEach((v: any) => {
                        switch (v.feature) {
                            case 'organization':
                                v.modelLabel = 'Organização';
                                break;
                            case 'contact':
                                v.modelLabel = 'Contato';
                                break;
                            case 'vehicle':
                                v.modelLabel = 'Veículo';
                                break;
                            case 'product':
                                v.modelLabel = 'Produto';
                                break;
                            case 'financialproduct':
                                v.modelLabel = 'Investimento';
                                break;
                            case 'treatment':
                                v.modelLabel = 'Tratativa';
                                break;
                            case 'ticket':
                                v.modelLabel = 'Ticket';
                                break;
                            case 'task':
                                v.modelLabel = 'Tarefa';
                                break;
                            case 'schedule':
                                v.modelLabel = 'Atividade';
                                break;
                            case 'communicationCustomer':
                                v.modelLabel = 'Disparos da régua';
                                break;
                            default:
                                v.modelLabel = 'Outros';
                                break;
                        }
                    });

                    resolve(data ? data.plain() : null);
                }, reject);
        });
    }

    getCustomFieldBlocks = () => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('customfieldblocks').getList()
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }

    getBrazilianStates = () => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('states').getList()
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }

    getCitiesOfBrazilianState = (stateId: any) => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('states').customGET('cities', {id: stateId})
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }

    getCitiesOfBrazilianStateByName = (stateId: any) => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('states').customGET('cities', {name: stateId})
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }
}
