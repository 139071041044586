class AppController implements angular.IComponentController {
    form: any;

    constructor(
        private $rootScope: any,
        private $scope: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.$rootScope.formSettings = this.form;
    }
}

export class AppComponent implements angular.IComponentOptions {
    static selector = 'app';
    static bindings = {
        form: '='
    };
    static controller = AppController;
    static template = require('./app.component.html');
}
