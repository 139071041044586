class AlertModalController implements angular.IComponentController {
    resolve: any;
    close: ($event?: any) => void;
    dismiss: ($event?: any) => void;
    onConfirm: ($event?: any) => void;
    show: boolean;
    type: string = 'error';
    loading: boolean;

    constructor(
        private $rootScope: any,
        private $scope: any,
    ) {
        'ngInject';
    }

    $onInit() {
        Object.assign(this, this.resolve.config);
    }

    confirm = async () => {

        if (!this.onConfirm) {
            return this.close();
        }

        if (this.onConfirm.constructor.name === 'AsyncFunction') {
            this.loading = true;
            await this.onConfirm();
            return this.close();
        }


        const ret = this.onConfirm();

        // @ts-ignore
        if (ret instanceof Promise) {
            this.loading = true;

            try {
                await ret;
                return this.close();
            } catch (e) {
                console.error(e);
                return this.dismiss({$value: {error: true}});
            }
        }

        return this.close();
    }
}

export class AlertModalComponent implements angular.IComponentOptions {
    static controller = AlertModalController;
    static selector = 'alertModal';
    static bindings = {
        resolve: '<',
        close: '&',
        dismiss: '&'
    };
    static template = require('./alertModal.html');
}
