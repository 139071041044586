export class StaffsService {

    static selector = 'StaffsService';

    constructor(private Restangular: any, private $rootScope: any) {
        'ngInject';

        return this;
    }

    getCurrentBranchStaffs = (permission: any) => {
        return new Promise((resolve, reject) => {
            this.Restangular.one('/staffs/role').get({permission: permission})
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }
}
