class ContentBodyController {
    constructor(
        private $transclude: any,
    ) {
        'ngInject';
    }
}

export class ContentBodyComponent implements angular.IComponentOptions {
    static selector = 'contentBody';
    static controller = ContentBodyController;
    static transclude = {
        preLoader: '?preLoader',
    };
    static template = `
        <div class="row pre-loader-container" style="margin: 0">
            <ng-transclude ng-transclude-slot="preLoader"></ng-transclude>
            <div class="col-xs-12 main-content col-md-12">
                <ng-transclude class="main-content-wrapper"></ng-transclude>
            </div>
        </div>
    `;
}
