import * as angular from 'angular';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import {config} from './i18n.config';
import {I18nComponent} from './i18n.controller';


export const moduleName = angular.module('application.i18n', [
    'pascalprecht.translate'
])
    .component(I18nComponent.selector, I18nComponent)
    .config(config)
    .name;
